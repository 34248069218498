var a = document.querySelector('.envelope__check');
a.addEventListener("click",function(){
  if(a.checked){var b =document.querySelector('.envelope__letter');setTimeout(()=>{b.classList.add("zoom-in");},600);document.querySelector('body').style.background="pink";}else{var b = document.querySelector('.envelope__letter');b.classList.remove("zoom-in");document.querySelector('body').style.background="white";}
});

$('#login input').on('keyup',function(){
  var password = $('#login input[name="password"]').val();
  if(password === "motasim"){
    $('#login').css("opacity","0");
    $('#login').css("pointer-events","none");
  }
});
$("#os-phrases > h2")
  .css('opacity', 1).lettering( 'words' )
  .children( "span" ).lettering()
  .children( "span" ).lettering();
function hideLoader(){
  $('#loader').css("display","none");
}
setTimeout(hideLoader,5500);
